import "./src/styles/global.scss";
import packageInfo from "./package.json";
import React, { useState, useEffect } from "react";

if (typeof window !== "undefined") {
  window.rollerWorld = window.rollerWorld || {};
  window.rollerWorld.version = packageInfo.version;
}


export const shouldUpdateScroll = ({ routerProps }) => {
  const { location } = routerProps;

  // Add your conditions here
  if (location.pathname === "/somePage") {
    return true;
  }

  window.scrollTo(0, 0);
  return false;
};
