import PropTypes from "prop-types";
import React, { useState, useContext, useEffect } from "react";
import { seoDataObj } from "../data/components/seo";
import withLocation from "../lib/withLocation";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "./main-layout.scss";
import { SEO } from "../components/seo";
import Lenis from "@studio-freight/lenis";
import ReactContext from "../context/reactContext";
import classNames from "classnames";

const LandingPageLayout = ({
  children,
  location,
  title = "",
  description = "",
  meta = [],
  layout,
}) => {
  const { pathname } = location;
  const [currentTeamMember, setCurrentTeamMember] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(null);

  useEffect(() => {
    const lenis = new Lenis({
      lerp: 0.1,
      duration: 1.3,
      wheelMultiplier: 1,
      touchMultiplier: 2,
      smoothWheel: true,
    });
    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    const rafId = requestAnimationFrame(raf);
    return () => {
      lenis.off("scroll");
      cancelAnimationFrame(rafId);
    };
  }, []);

  const seoData = seoDataObj[pathname] ||
    seoDataObj[pathname.substr(0, pathname.length - 1)] || {
      title,
      description,
      meta,
    };

  return (
    <ReactContext.Provider value={{currentTeamMember, setCurrentTeamMember, openDropdown, setOpenDropdown}}>
      <div className="rw-site">
        {seoData && <SEO {...seoData} />}
        <Header location={location} />
        <div className="rw-content">{children}</div>
        <Footer location={location} />
      </div>
    </ReactContext.Provider>
  );
};

LandingPageLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
};

export default withLocation(LandingPageLayout);
