import React from "react";
import DynamicLink from "../Link";
import "./footer.scss";
import classNames from "classnames";

const Footer = ({location}) => {

  const themeMap = {
    "/": "#faf3e1",
    "/afi": "#faf3e1",
    "/team": "#000",
  };

  const { pathname } = location;

  const creatorClasses = classNames("rw-footer__creators", {
    "rw-footer__creators--black": pathname === "/team",
  });

  return (
    <div className="rw-footer">
      <div className="rw-footer__inner">
        <div className="rw-footer__contact">
          <div className="rw-footer__links">
            <DynamicLink
              isOutbound
              to="https://www.instagram.com/rollerworldfilm/?igshid=NGVhN2U2NjQ0Yg%3D%3D"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="38"
                height="38"
                viewBox="0 0 24 24"
              >
                <path
                  fill={themeMap[pathname]}
                  d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3Z"
                />
              </svg>
            </DynamicLink>
            <DynamicLink
              isOutbound
              to="mailto:slin@afi.edu?cc=kcaicedo@afi.edu"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="38"
                height="38"
                viewBox="0 0 24 24"
              >
                <path
                  fill={themeMap[pathname]}
                  d="M4 20q-.825 0-1.413-.588T2 18V6q0-.825.588-1.413T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.588 1.413T20 20H4Zm8-7L4 8v10h16V8l-8 5Zm0-2l8-5H4l8 5ZM4 8V6v12V8Z"
                />
              </svg>
            </DynamicLink>
          </div>
        </div>
        <div className={creatorClasses}>
          <DynamicLink isOutbound to="https://www.danielrbogart.com/">
            Coded by <span>Daniel Bogart</span>
          </DynamicLink>
          <DynamicLink isOutbound to="https://www.viladomatstudio.com/">
            Designed by <span>Begoña Viladomat</span>
          </DynamicLink>
        </div>
      </div>
    </div>
  );
};

export default Footer;
