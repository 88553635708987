import React, { useRef, useState, useContext } from "react";
import DynamicLink from "../Link";
import classNames from "classnames";
import { navLinks } from "../../data/components/header";
import { useLocation } from "@reach/router";
import ReactContext from "../../context/reactContext";
import CTA from "../Button";
import "./header.scss";
import { StaticImage } from "gatsby-plugin-image";

const Header = () => {
  const location = useLocation();
  const { pathname } = location;
  const [showMobile, setShowMobile] = useState(false);
  const { setCurrentTeamMember } = useContext(ReactContext);

  const themeMap = {
    "/": "hollow",
    "/afi": "black",
    "/team": "black",
  };

  const iconThemeMap = {
    "/": "#faf3e1",
    "/afi": "#000",
    "/team": "#000",
  };

  const handleChecked = () => {
    if (!showMobile) {
      setShowMobile(true);
    } else {
      setShowMobile(false);
    }
  };

  const mobileNavTriggerRef = useRef();

  const headerInner = classNames("rw-header__inner", {
    "rw-header__inner--mobile": showMobile,
  });

  const navMenuClasses = classNames("rw-header__mobile-nav-menu", {
    "rw-header__mobile-nav-menu--mobile": showMobile,
  });

  const linksClasses = classNames("rw-header__links", {
    "rw-header__links--open": showMobile,
  });

  const headerClasses = classNames("rw-header", {
    "rw-header--black": pathname === "/team" || pathname === "/afi",
  });

  return (
    <header className={headerClasses}>
      <div className={headerInner}>
        <div className={linksClasses}>
          {navLinks.map((item) => {
            const { title, url } = item;
            const navLinkClasses = classNames("rw-header__nav-link", {
              "rw-header__nav-link--active": pathname === url,
              "rw-header__nav-link--black--active":
                pathname === url &&
                (pathname === "/afi" || pathname === "/team"),
            });
            return (
              <div
                key={title}
                onClick={() => {
                  if (showMobile) {
                    handleChecked();
                  }
                  setCurrentTeamMember(null);
                }}
                className={navLinkClasses}
              >
                <DynamicLink to={url}>{title}</DynamicLink>
              </div>
            );
          })}
        </div>
        <DynamicLink className="rw-header__logo" to="/">
          <StaticImage
            src="../../images/rwLogoBlack.png"
            alt="Roller World Logo Black"
          />
        </DynamicLink>
        <div className="rw-header__cta-links">
          <DynamicLink
            className="rw-header__cta-links__insta"
            isOutbound
            to="https://www.instagram.com/rollerworldfilm/?igshid=NGVhN2U2NjQ0Yg%3D%3D"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="38"
              height="38"
              viewBox="0 0 24 24"
            >
              <path
                fill={iconThemeMap[pathname]}
                d="M12.001 9a3 3 0 1 0 0 6a3 3 0 0 0 0-6Zm0-2a5 5 0 1 1 0 10a5 5 0 0 1 0-10Zm6.5-.25a1.25 1.25 0 0 1-2.5 0a1.25 1.25 0 0 1 2.5 0ZM12.001 4c-2.474 0-2.878.007-4.029.058c-.784.037-1.31.142-1.798.332a2.886 2.886 0 0 0-1.08.703a2.89 2.89 0 0 0-.704 1.08c-.19.49-.295 1.015-.331 1.798C4.007 9.075 4 9.461 4 12c0 2.475.007 2.878.058 4.029c.037.783.142 1.31.331 1.797c.17.435.37.748.702 1.08c.337.336.65.537 1.08.703c.494.191 1.02.297 1.8.333C9.075 19.994 9.461 20 12 20c2.475 0 2.878-.007 4.029-.058c.782-.037 1.308-.142 1.797-.331a2.91 2.91 0 0 0 1.08-.703c.337-.336.538-.649.704-1.08c.19-.492.296-1.018.332-1.8c.052-1.103.058-1.49.058-4.028c0-2.474-.007-2.878-.058-4.029c-.037-.782-.143-1.31-.332-1.798a2.912 2.912 0 0 0-.703-1.08a2.884 2.884 0 0 0-1.08-.704c-.49-.19-1.016-.295-1.798-.331C14.926 4.006 14.54 4 12 4Zm0-2c2.717 0 3.056.01 4.123.06c1.064.05 1.79.217 2.427.465c.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428c.047 1.066.06 1.405.06 4.122c0 2.717-.01 3.056-.06 4.122c-.05 1.065-.218 1.79-.465 2.428a4.884 4.884 0 0 1-1.153 1.772a4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.427.465c-1.067.047-1.406.06-4.123.06c-2.717 0-3.056-.01-4.123-.06c-1.064-.05-1.789-.218-2.427-.465a4.89 4.89 0 0 1-1.772-1.153a4.905 4.905 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428c-.048-1.066-.06-1.405-.06-4.122c0-2.717.01-3.056.06-4.122c.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772a4.897 4.897 0 0 1 1.772-1.153c.637-.248 1.362-.415 2.427-.465C8.945 2.013 9.284 2 12.001 2Z"
              />
            </svg>
          </DynamicLink>
          <CTA
            theme={themeMap[pathname]}
            className="rw-header__cta-links__cta"
            to="https://www.afi.com/support/?Op=donate&don=500"
            isOutbound
            glow={true}
          >
            Donate
          </CTA>
        </div>
      </div>
      <input
        aria-label="Open mobile nav"
        className="rw-header__mobile-nav-trigger"
        ref={mobileNavTriggerRef}
        type="checkbox"
        onChange={handleChecked}
      />
      <div className={navMenuClasses}>
        <span />
        <span />
        <span />
      </div>
    </header>
  );
};

export default Header;
