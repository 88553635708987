import React, { useEffect, useRef, useState } from "react";
import DynamicLink from "../Link";
import gsap from "gsap";
import classNames from "classnames";
import "./cta.scss";

const CTA = ({ children, to, isOutbound = false, className, glow=false, theme }) => {
  const waterFillRef = useRef(null);
  const ctaRef = useRef(null);
  const [tl, setTimeline] = useState(null);

  const ctaClasses = classNames("rw-cta", {
    "rw-cta--hollow": theme === "hollow",
    "rw-cta--black": theme === "black",
  });

  useEffect(() => {
    const waterFillElement = waterFillRef.current;
    const ctaElement = ctaRef.current;

    const waterFillAnimation = gsap
      .timeline({ paused: true })
      .to(waterFillElement, {
        duration: 0.5,
        scaleX: 1,
        ease: "power2.out",
        x: -38,
      });
    setTimeline(waterFillAnimation);

    if (glow) {
      gsap.to(ctaElement, {
        backgroundPosition: "300px 0px",
        duration: 1,
        repeat: -1,
        yoyo: true,
        repeatDelay: 4,
      });
    }
  }, [glow]);

  const handleMouseOver = () => {
    if (tl) {
      tl.timeScale(1).play();
    }
  };

  const handleMouseLeave = () => {
    if (tl) {
      tl.timeScale(2).reverse();
    }
  };

  return (
    <div
      ref={ctaRef}
      className={`${ctaClasses} ${className} ${glow ? "glow" : ""}`}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <DynamicLink isOutbound={isOutbound} to={to}>
        {children}
      </DynamicLink>
      <div ref={waterFillRef} className="rw-cta__water-fill"></div>
    </div>
  );
};

export default CTA;
