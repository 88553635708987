export const navLinks = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Team",
    url: "/team",
  },
  {
    title: "AFI",
    url: "/afi",
  },
];